.learner-area {

  .learner-copy{
    text-align:center;
    h2{
      display: block;
      margin-top: 30px;
      font-size: 14px;
      font-weight: bold;
    }

    img{
      max-width:100%;
    }
  }

  @media (max-width: 768px) {
    .alert {

    }
  }

  .todo-list.small-list > li {
    background: rgb(236, 236, 241);
    position: relative;
    padding-right: 30px;
    button {
      position: absolute;
      border: 2px solid #2574a9;
      color: #2574a9;
      border-radius: 4px;
      margin-left: 20px;
      right: 4px;
      top: 4px;
      background: none;
    }
  }

  .multiselect__option {
    min-height: 22px;
    padding-top: 10px;
    font-size: 13px;
    border-radius: 10px;
  }
  .multiselect__option--highlight {
    background: #1585BC !important
  }
  .multiselect__tags {
    border-radius: 10px;
    padding: 10px;
    min-height: 30px;
    border: 1px solid $form-border-color;
  }
  .multiselect__input {
    padding: 10px 0 0 15px;
  }
  .multiselect {
    min-height: 30px;
  }
  .multiselect__tag {
    background: #56b947;
    padding: 4px 10px;
    height: auto;
    border-radius: 8px;
    font-size: 13px;

    i {
      color: $white;
      border-radius: 0;

      &:hover {
        background-color: transparent;
      }
    }
  }

  #side-menu {
    margin-top: 10px;
  }

  #side-menu,
  #side-menu-bottom {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  #side-menu-bottom {
    position: absolute;
    bottom: -150px;
    width: 100%;

    @media (max-width: 768px) {
      position: relative;
      bottom: auto;
    }
  }

  #side-menu-bottom li {
    text-align: center;
  }

  .row.learner-dashboard-module {
    .col-md-4.programme {
      a,
      h2 {
        display: inline-block;
      }

      a {
        text-decoration: underline;
      }
    }

    .unit,
    a {
      color: #2574a9;
      font-weight: bold;
    }

    .unit {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 15px;
      padding: 15px;
      border: none;
      border-radius: 10px;
      background: #FFFFFF;

      svg {
        margin-right: 15px;
        flex-shrink: 0;
      }

      div.unit-info > div {
        color: initial;
        font-weight: normal;
      }
    }
  }

  .circle {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    svg {
      circle:nth-child(3) {
        stroke-linecap: square;
        stroke: #56b947;
      }

      circle:nth-child(2) {
        stroke: #dce6ed;
      }
    }

    .title {
      display: block;
      margin-top: 20px;
      font-size: 14px;

      @media (max-width: 768px) {
        color: #8bb1c4;
        position: absolute;
        width: 100%;
        top: -60px;
        margin-top: 0px;
        text-align: center !important;

        span {
          width: 100%;
          text-align: center !important;
        }
      }

      .user {
        font-weight: bold;
      }
    }

    .radial-progress-container {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 100%;

      p {
        margin-bottom: 0;
        line-height: 100%;
      }

      .bar-left-digital {
        color: #2574a9;
        font-size: 60px;
        margin-bottom: 5px;
      }

      .bar-left-line {
        color: #2574a9;
        font-size: 18px;

      }

      .bar-summary-line {
        max-width: 180px;
        text-align: center;
        font-size: 13px;
        line-height: 1.42857143;
        margin-top: 5px;
      }
    }

    .problem-solving {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      padding: 25px;
      border-radius: 10px;
      background: #FFF;

      h2 {
        color: #2574a9;
        font-size: 18px;
        font-weight: bold;
        width: 100%;
        text-align: center;
      }

      p {
        width: 100%;
        text-align: center;
        font-size: 12px;
      }

      .unit-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        text-align: center;
        color: #2574a9;
        border: 3px solid #2574a9;
        border-radius: 100%;
        font-weight: bold;
        // @media (max-width: 768px) {
        //   font-weight: bold;
        //   border: none;
        //   display: inline;
        //
        //   & + h2 {
        //     display: inline;
        //   }
        // }
      }
    }
  }

  .qualification-add,
  .save,
  .new-msg {
    width: auto;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    border: 2px solid #2574a9;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #2574a9;
    background: transparent !important;

    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .button.log {
    width: 100%;
  }
  .button.new-msg.gro-con {
    text-decoration: none;
    width: 80%;
    // position: fixed;
    // bottom: 60px;
    // left: 50%;
    // transform: translateX(-50%);
  }

  .qualification-add:hover,
  .new-msg:hover,
  .save:hover,
  .button:hover {
    color: #fff !important;
    background: #2574a9 !important;
  }

  @media (max-width: 768px) {
    .new-msg {
      // display:none;
      position: fixed;
      width: 80%;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 20px auto;
      background: rgb(243, 243, 244) !important;
      z-index: 10;
    }

    .log-con {
      bottom: 20px !important;
    }
  }

  .col-md-4 {
    .title {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      margin-bottom: 30px;
      &.week {
        align-items: center;
        display: flex;
        flex-direction: column;
      }

      h2 {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
  //MY PROGRAMME

  .modules-group {
    margin-bottom: 50px;
    // padding: 15px;
    opacity: 1;
    border-radius: 10px;
    background: #FFF;

    .module-title-date {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px;
      cursor: pointer;
      border-bottom: 4px solid #f3f3f4;

      div {
        font-size: 16px;
      }

      div:first-child {
        color: #2574a9;
        font-weight: bold;
      }
      div:nth-child(2) {
        text-align: right;
      }
    }

    .module-unit {
      position: relative;
      display: flex;
      flex-direction: row;
      padding: 25px;
      border-bottom: 1px solid #f3f3f4;

      a {
        text-decoration: underline;
        color: #2574a9;
        font-weight: bold;
      }

      svg {
        margin-right: 15px;
        flex-shrink: 0;
      }

      &.module {
        justify-content: space-between;
      }

      .unit-details {
        margin-right: 40px;
        min-width: 150px;

        div:first-child {
          color: #2574a9;
          font-weight: bold;
        }

        &.single {
          display: flex;
        }

        > h4 {
          margin-top: 0;
        }
      }

      .log-btn {
        align-self: center;

        a {
          padding: 10px 35px;
          text-decoration: none;
        }
      }

      &.skill {
        flex-direction: column;
        font-size: 14px;

        .main-divide {
          display: flex;
          justify-content: space-between;
          color: #56b947;
          font-weight: bold;
          @media (max-width: 768px) {
            flex-direction: column-reverse;
            align-items: flex-start;
          }

          .unit-type {
            display: flex;
            align-items: center;
            padding: 15px 20px;
            border-radius: 10px;
            background: #f3f3f4;
          }
        }

        .criteria-number {
          display: inline-block;
          margin-right: 30px;
        }

        .criteria {
          display: inline;
        }
      }
    }
  }
  .module-unit-item {
    position: relative;
    background-color: #f4f4f7;

    &.first-unit {
      &::after, &::before {
        top: -1px;
        left: 10px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      &::after {
        border-top-color: #ffffff;
        border-width: 10px;
        margin-left: 25px;
        z-index: 2;
      }
      &::before {
        border-top-color: #f3f3f4;
        border-width: 11px;
        margin-left: 24px;
        z-index: 2;
      }
    }
  }

  .content-resource-list {
    svg img {
      width: 20px;
    }
    .href {
      border: none;
      padding: 10px;
    }
    .content-resource-hide {
      display: none;
    }
    .content-resource-title {
      color: rgb(37, 116, 169);
      cursor: pointer;
      margin-top: 10px;
    }
  }

  .modules-disable {
    pointer-events: none !important;
    opacity: 0.5 !important;
  }

  .col-md-4.links {
    .resources {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .href {
    display: flex;
    padding: 25px 0;
    border-bottom: 1px solid #e8e8eb;

    svg {
      margin-right: 15px;
      flex-shrink: 0;
    }

    .resource-img {
      width: 40px;
      height: 40px;
      //background: #dadada;
      border-radius: 100%;
      margin-right: 20px;
      flex-shrink: 0;


      i {
        width: 40px;
        height: 40px;
        font: normal normal normal 18px/1 FontAwesome;
        color: rgb(47, 151, 210);
        text-align: center;
        line-height: 35px;
        border-radius: 20px;
        border: 3px solid rgb(47, 151, 210);
      }

      i.individual {
        width: 40px;
        height: 40px;
        font: normal normal normal 18px/1 FontAwesome;
        color: rgb(86, 185, 71);
        text-align: center;
        line-height: 35px;
        border-radius: 20px;
        border: 3px solid rgb(86, 185, 71);
      }
    }

    .link-file {
      display: flex;
      flex-direction: column;
      line-height: 35px;
      overflow-x: hidden;

      a {
        text-decoration: underline;
        color: #2574a9;
        font-weight: bold;

      }
    }
  }

  .todo {
    position: relative;
    //height: 60vh;
  }

  //resources
  .resources-list {
    position: relative;

    svg {
      flex-shrink: 0;
    }

  }

  .no-resources {
    position: absolute;
    background: url('/images/loader-silver.png') no-repeat 50% 0%;
    height: 320px;
    padding-top: 200px;
    text-align: center;
    top: 40%;
    transform: translateY(-40%);
    font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 13px;
    color: #676a6c;
    width: 100%;

    h1 {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .col-md-4.tag-select {
    .dropdown-toggle {
      border: 1px solid $form-border-color;
      border-radius: 10px !important;
      background: #FFF;
      font-size: 14px;
      z-index: 10;

      .dropdown-menu {
        width: 100%;
      }

      input.form-control {
        padding: 10px 15px;
      }

      input[type=search] {
        font-size: 12px;
      }
    }
  }
  //navbar

  .navbar-default.navbar-static-side {
    .search {
      position: relative;
      display: flex;
      align-items: center;

      svg {
        position: absolute;
        top: 10px;
        right: 15px;
        cursor: pointer;
      }
    }

    input[type="submit"] {
      // visibility: hidden;
      display: none;
    }

    .notification {
      position: relative;

    }
  }

  .notification-counter-message,
  .notification-counter {
    position: absolute;
    top: 10px;
    right: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    color: #FFF;
    border-radius: 100%;
    background: #56b947;
  }

  #search-nav-text,
  #send-msg,
  #input-new-group,
  #input-new-log {
    padding: 15px;
    border: 1px solid $form-border-color;
    border-radius: 10px !important;
    background: #FFF;
    font-size: 14px;
    outline: none;
  }
  #input-new-log {
    width: 100%;
  }

  .no-icon {
    text-align: center;
    display: inline-block;
    width: 100px;
    height: 100px;
    border: 3px solid rgb(37, 116, 169);
    border-radius: 50px;
  }

  .no-messages-icon {
    background: url('/images/messages.png') no-repeat 50% 50%;
  }

  .no-logs-icon {
    background: url('/images/pencil.png') no-repeat 50% 50%;
  }

  .no-todos-icon {
    background: url('/images/todo.png') no-repeat 50% 50%;
  }

  .no-items {
    position: absolute;
    text-align: center;
    top: 100px;
    font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: normal;
    color: #676a6c;
    width: 100%;

    h1 {
      font-size: 16px;
      font-weight: bold;
      color: rgb(37, 116, 169);
    }
  }

  .no-items-column {
    top: 0;
    position: relative;
    margin: 50px 0;
  }

  .single-msg {
    display: flex;
    width: 100%;
    padding: 25px 0;
    border-bottom: 1px solid #e8e8eb;

    .initials {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 40px;
      height: 40px;
      margin-right: 15px;
      text-align: center;
      color: #2574a9;
      border: 3px solid #2574a9;
      border-radius: 100%;
      font-weight: bold;
    }

    .groupicon {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 40px;
      height: 40px;
      margin-right: 15px;
      text-align: center;
      color: #2574a9;
      font-weight: bold;
    }

    .name-date {
      display: flex;
      justify-content: space-between;
      width: 100%;
      color: #2574a9;
      font-weight: bold;

      a {
        text-decoration: underline;
        color: #2574a9;
        font-weight: bold;
      }
    }

    &:last-child {
      margin-bottom: 50px;
    }
  }

  .new-msg-btn-container {
    width: 100%;
    align-self: center;
    .button.new-msg {
      padding: 10px 60px;
      color: #2574a9;
      font-size: 16px;
    }
    @media (max-width: 768px) {
      width: 95%;
      position: fixed;
      bottom: 50px;
      .button.new-msg {
        width: 100%;
      }
    }
  }

  //groups

  .col-md-4.groups-container {
    // display: flex;
    flex-direction: column;
    font-weight: bold;

    a {
      text-decoration: underline;
      color: #2574a9;
    }

    .new-group {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
    }

    .single-group {
      display: flex;
      margin-bottom: 10px;
      padding: 10px;
      border-radius: 10px;
      background: #FFF;
      position: relative;

      svg {
        margin-right: 15px;
      }

      .single-group-name-container {
        flex-direction: column;
      }

      &__name {
        color: #2574a9;
        font-weight: bold;
      }

      &__members {
        font-weight: normal;
      }
    }

    .single-group .group-delete {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }
  }

  //create group

  .create-new-group {

    label {
      color: #2b2b2b;
      display: inline-block;
      margin-bottom: 20px;
    }

    #input-new-group {
      width: 100%;
    }

    .dropdown.v-select.searchable {
      // max-height: 38px;
      // padding: 10px 15px;
      border: 1px solid #d2d2d2;
      border-radius: 10px !important;
      background: #FFF;
      font-size: 14px;
      outline: none;
    }

    input.group__start-chat {
      margin-top: 30px;
      border: 1px solid #2095d2;
      border-radius: 10px;
      background: transparent;
      color: #2095d2;
      font-weight: bold;
      padding: 10px;
    }
  }

  //chat

  .chat-avatar {
    width: 38px;
    height: 38px;
    overflow: hidden;
    border-radius: 4px;
    margin: 0;

    img {
      max-width: 100%;
      border-radius: 4px;
    }
  }

  .chat-avatar-left {
    float: left;
    margin-right: 10px;
  }

  .chat-avatar-right {
    float: right;
    margin-left: 10px;
  }

  .row.msg {
    .log-link {
      padding: 10px 0;
      text-align: center;
      a {
        color: rgb(37, 116, 169);
        font-weight: bold;
      }
    }
  }
  .send-msg-container {
    position: relative;
    //display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    box-sizing: border-box;
    margin-top: 30px;

    @media (max-width: 768px) {
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 20px;
      background: rgb(243, 243, 244);
      width: 100%;
      margin: 0;
    }

    #send-msg {
      width: 100%;
      padding: 40px 35px;
    }

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 40px;
      cursor: pointer;
    }

  }
  .msg-container {
    height: 80%;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: flex-end;
  }
  .chat-messages {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-self: center;
    // justify-content: flex-end;
    overflow: auto;
    margin-bottom: 30px;
    min-height: 100px;

    @media screen and (max-width: 992px) {
      width: 90%;
    }

    @media screen and (max-width: 640px) {
      width: 100%;
      overflow-x: hidden;
    }

    .single-msg {
      padding: 20px;
      min-height: fit-content;
      color: #FFF;
      position: relative;
      max-width: 75%;
      min-width: 250px;
      width: 100%;
      margin-bottom: 40px;
      border-radius: 10px;

      @media screen and (max-width: 640px) {
        min-width: 80px;
      }

      &.theirs-msg {
        background: #5c5c5c;
        align-self: flex-start;
        border-bottom-left-radius: 0;
        margin-left: 10px;
      }
      &.ours-msg {
        background: #2095d2;
        align-self: flex-end;
        border-bottom-right-radius: 0;
        float: right;

        @media screen and (max-width: 440px) {
          float: inherit;
        }
      }

      .msg-datestamp {
        font-size: 10px;
        position: absolute;
        bottom: -20px;
        color: #2b2b2b;
        &.ours-datestamp {
          right: 0;
        }
        &.theirs-datestamp {
          left: 0;
        }
      }
    }
  }

  //log it
  .qualification-select {

    border-radius: 10px 0 0 10px;
    height: 46px;

    option {
      font-size: 13px;
      padding: 10px;
    }
    option:disabled {
      padding-left: 0px;
      background: rgb(243, 243, 244);
    }
  }

  .log-svg {
    margin-right: 15px;
  }
  .log-it.button.log {
    color: #2574a9;
    padding: 10px 50px;
    margin-top: 50px;
    width: auto;
  }
  a {
    display: inline-block;
  }

  .bio-text {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
    textarea {
      width: 100%;
      height: 75px;
      resize: none;
      padding: 25px;
      border-radius: 10px;
      display: block;
      border: 1px solid $form-border-color;
      outline: none;
    }
    label {
      text-align: center;
      width: 100%;
    }
  }

  .profile-avatar {
    text-align: center;
    label {
      text-align: center;
      width: 100%;
    }
    img {
      max-width: 320px;
      display: inline-block;
    }
  }

  .log-text {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
    textarea {
      width: 100%;
      height: 150px;
      resize: none;
      padding: 15px;
      border-radius: 10px 10px 0 0;
      display: block;
      border: 1px solid $form-border-color;
      outline: none;
    }
    .log-tags {
      box-sizing: border-box;
      border: 1px solid $form-border-color;
      border-top: none;
      height: auto;
      width: 100%;
      background: #FFF;
      border-radius: 0 0 10px 10px;
      padding: 10px;

      .dropdown-toggle {
        border: none !important;
      }

      .dropdown-toggle span.selected-tag {
        background: #56b947;
        color: $white;
        height: auto;
        padding: 4px 10px;
        border: none;
        border-radius: 8px;

        button[type=button].close {
          color: $white;
          opacity: 1;
          text-shadow: none;
          font-weight: normal;
        }
      }

      .v-select {
        .dropdown-menu {
          border-color: $form-border-color;
          box-shadow: none;
          margin: 0;
          padding: 0;
          top: 100%;
          left: -11px;
          width: calc(100% + 22px);
        }

        .selected-tag {
          margin-top: 0;
          margin-bottom: 10px;

          .close {
            font-size: 15px;
            color: #fff !important;
          }
        }

        .open-indicator {
          right: 15px;
        }
      }
    }
  }
  .log-time {
    margin-bottom: 20px;
    select {
      width: 100%;
      display: block;
      border: 1px solid $form-border-color !important;
      border-radius: 10px !important;
      background: #FFF;
      font-size: 14px;
      outline: none;
      padding: 10px;
      height: 43px;
      &:disabled {
        background: #f9f9fa;
        border: 1px solid #e2e2e3;
        //color: #e2e2e3;
      }
    }
  }

  .log-link {

    input[type="button"] {
      padding: 10px !important;
    }

    label {
      font-weight: bold;
      span {
        font-weight: normal;
      }
    }
    select {
      width: 100%;
      display: block;
      border: 1px solid #d2d2d2;
      border-radius: 10px 0px 0px 10px !important;
      background: #FFF;
      font-size: 14px;
      outline: none;
      padding: 11px;

      &:disabled {
        background: #f9f9fa;
        border: 1px solid #e2e2e3;
        color: #e2e2e3;
      }
    }
    .log.save {
      color: #2574a9;
      font-weight: bold;
      padding: 10px 15px;
      background: transparent;
      border: 2px solid #2574a9;
      border-radius: 10px;
      outline: none;
      font-size: 16px;
      background: transparent !important;
    }
  }
  .single-attachment {
    position: relative;
    padding: 10px;
    width: 100%;
    background: #FFF;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    margin-bottom: 20px;
    .attachment-img {
      width: 40px;
      height: 40px;
      //background: #dadada;
      border-radius: 100%;
      margin-right: 20px;
      flex-shrink: 0;

      i {
        width: 40px;
        height: 40px;
        font: normal normal normal 18px/1 FontAwesome;
        color: rgb(47, 151, 210);
        //font-weight: bold;
        text-align: center;
        line-height: 35px;
        border-radius: 20px;
        border: 3px solid rgb(37, 116, 169);
      }
    }
    .attachment-details {
      display: flex;
      width: 100%;
      flex-direction: column;
      .attachment-name {
        color: #2574a9;
        font-weight: bold;
        padding-right: 15px;
        text-overflow: ellipsis;
        word-break: break-all;
      }
      .attachment-descr {
        font-size: 12px;
        color: #8d99a2;
      }
      .attachment-delete {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
      }
    }

  }
  .log-attachment {
    display: flex;
    align-items: center;
    flex-direction: column;

    a {
      color: #2574a9;
      text-decoration: underline;
      font-weight: bold;
    }

    .vue-fine-uploader-file-input {
      i {
        display: none;
      }
      a {
        margin-top: 50px;
      }
    }

    .btn-primary {
      background-color: transparent !important;
      border-color: transparent !important;
      width: 100%;
      text-align: center;
    }

    .btn {
      font-size: 13px;
      color: #2574a9;
      text-decoration: underline;
      font-weight: bold;
      box-shadow: none;
      margin-top: 30px;
    }

    .vue-fine-uploader-file-input,
    .vue-fine-uploader-file-input label {
      width: 100%;
      text-align: center;
    }

    .loading-message {
      text-align: center;
      margin-top: 10px;
    }
  }

  //navbar

  .nav-front {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .col-md-4.circle.mobile {
    display: none;
  }

  @media screen and (max-width: 992px) {
    .col-md-4.circle.mobile {
      display: flex;
    }
    .col-md-4.circle .circle.dash {
      display: none;
    }
  }
  @media screen and (min-width: 993px) {
    .col-md-4.circle.mobile {
      display: none;
    }
    .col-md-4.circle .circle.dash {
      display: flex;
    }
  }

  .nav.nav-pills {
    display: none;
    justify-content: center;
    li a {
      color: #8bb1c4;
    }
    li.active {
      background: none;
      border-left: none;
      a {
        background-color: transparent;
        color: #2095d2;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .nav.nav-pills {
      display: flex;
    }
    .tab-pane {
      display: none;
      &.active {
        display: block;
        border-left: none;
      }
    }
  }
  @media screen and (min-width: 993px) {
    .tab-pane {
      display: block !important;
    }
  }

  @media (max-width: 768px) {
    li.nav-header {
      img {
        visibility: hidden;
      }
    }
    .navbar-static-side {
      font-size: 16px;
      // display: none !important;
      width: 100% !important;
      height: 100%;
      z-index: 200 !important;
      background: #F3F3F4;
      // background: black;
      position: fixed !important;
      li.search-nav {
        width: 90%;
        #search-nav-text {
          width: 100%;
        }
      }
    }
    .dashboard-header {
      position: absolute;
      top: 80px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .col-md-4 + div {
        //display: none !important;
      }
    }
  }
  @media (min-width: 769px) {
    .navbar-static-side {
      display: block !important;
    }

  }
  .navbar-header {
    // float: right;
    height: 75px;
    width: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    background: #F3F3F4;
    @media (min-width: 769px) {
      display: none;
    }
    img {
      height: 75px;
      position: fixed;
    }
    .navbar-minimalize {
      z-index: 300;
      // align-self: center;
      position: fixed;
      top: 10px;
      right: 10px;
      height: 30px;
      i {
        color: #05405c;
        font-size: 30px;
      }
      & + .notification-counter {
        z-index: 300;
        right: 35px;
        top: 5px;
      }

    }
    div.bars-minimalize {
      position: absolute;
      top: 10px;
      right: 10px;

    }
  }

  .dashboard-header, .breadcrumb {
    background: transparent;
  }

  .title .notification {
    position: relative;
    width: 100px;
    .notification-counter {
      top: 15px;
    }

  }

  @media (max-width: 768px) {
    .wrapper.wrapper-content {
      margin-top: 80px;
    }
    .mobile-hide {
      display: none !important;
    }
    .single-group:nth-child(2) {
      margin-top: 20px;
    }
    #con, #gro {
      padding-bottom: 50px;
    }
    .group__start-chat {
      display: none;
    }
    .msg-datestamp {
      display: none;
    }
    .chat-messages .single-msg {
      max-width: 80%;
      padding: 20px;
    }
    .send-msg-container #send-msg {
      padding: 30px 20px;
    }

  }

  @media (min-width: 769px) {
    .gro-con {
      display: none;
    }
  }

  .desktop-msg {
    margin-top: 30px;
    padding-left: 50px;
    padding-right: 50px;
    text-decoration: none !important;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .btn-con {
    position: fixed;
    width: 100%;
    height: 100px;
    bottom: 0;
    background: #F3F3F3;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 769px) {
      display: none !important;
    }
  }

  .btn-green {
    color: rgb(86, 185, 71) !important;
    border: 2px solid rgb(86, 185, 71) !important;
  }

  .btn-green:hover {
    background: rgb(86, 185, 71) !important;
  }

  .questionair-answers {
    margin-top: 30px;
    a {
      color: #2095d2;
      border-color: #2095d2;
      margin-right: 10px;
      font-size: 14px;
      font-weight: normal;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  //wrap columns
  @media (min-width: 768px) {
    .wrap {
      display: flex !important;
      flex-flow: column wrap;
      height: 700px;
      ///////////////////////////// !!!!!!!!!!!!!!!!! ^
      .href, .single-msg, .single-msg.log-it-content {
        margin-right: 20px;
        max-width: 33%;
      }
    }
    .half-eight {
      .single-msg {
        max-width: 40%;
        margin-right: 20px;
      }
    }
  }
  @media (max-width: 767px) {
    .wrap {
      padding-bottom: 100px !important;
    }
    .nav-front {
      width: 100%;
      text-align: center;
      .col-md-4 {
        width: 100%;
      }
      .col-md-4 ol {
        text-align: center;
        width: 100%;
      }
      li {
        width: 100%;
        display: inline;
        padding-left: 0 !important;
      }
      li:first-child {
        width: auto;
      }
    }
  }

  .nav.nav-pills {
    margin-bottom: 30px;
  }

  .breadcrumb > li + li:before {
    display: none;
  }
  .breadcrumb > li:first-child {
    font-weight: bolder;
    width: 10px;
    height: 10px;
  }
  .breadcrumb > li {
    z-index: 199;
    display: inline;
    font-weight: bold;
    padding-left: 40px;
    &:first-child {
      padding-left: initial;
    }
    .chat-to {
      text-decoration: underline;
    }

    @media (max-width: 768px) {
      color: #8bb1c4 !important;
    }
  }

  .breadcrumb + div {
    .button.new-msg {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }
  }

  // li.active {
  //   &::before {
  //     content: '';
  //     position: absolute;
  //     left: 0;
  //     top: 5px;
  //     width: 10px;
  //     height: 10px;
  //     border-right: 0.2em solid black;
  //     border-top: 0.2em solid black;
  //      transform: rotate(225deg);
  //   }
  // }

  @media (max-width: 992px) {
    .tablet-hide {
      display: none !important;
    }
  }
  @media (min-width: 993px) {
    .desktop-hide {
      display: none !important;
    }
  }
  .nav-header {
    padding: 0;
  }

  .col-lg-12 .col-md-4.tag-select {
    div:first-child {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  #side-menu-bottom {
    width: auto;
    position: fixed;
    bottom: 0;
    width: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  #page-wrapper {
    padding: 0 60px;
  }

  .conversation-container {
    position: relative;
  }

  //login screen mobile
  @media (max-width: 768px) {
    #page-wrapper {
      padding: 0 15px 30px 15px;
    }

    .loginColumns, .centermessage, .ibox {
      height: 100vh;
      width: 100vw;
      .login-logo, .m-t-lg {
        height: 50vh;
      }
    }
    .ibox {
      margin-bottom: 0;
      max-width: none !important;
    }
    .login-logo {
      display: flex;
      align-items: center;
      justify-content: center;
    }

  }

  .col-md-4.circle.desktop-hide {
    margin-bottom: 20px;
    .radial-progress-container {
      p {
        color: #2095d2;
      }

      .bar-left-digital {
        font-size: 32px;
      }

      .bar-left-line {
        font-size: 12px;
      }
      svg {
        circle {
          stroke-width: 5px !important;
        }
      }

    }
  }

  #side-menu li {
    width: 100%;

    a {
      position: relative;
    }
  }

  .content-title {
    font-size: 16px;
  }

  .module-process svg {
    -webkit-filter: grayscale(100%) !important;
    filter: grayscale(100%) !important;
  }

  .module-finished svg {
    -webkit-filter: none !important;
    filter: none !important;
  }


}

[v-cloak] {
  display: none !important;
}

.no-margin{
  margin:0 !important;
  margin-top:0 !important;
  margin-bottom:0 !important;
  margin-left:0 !important;
  margin-right:0 !important;
}

.task-icon {
  display:flex;
  margin-right: 15px;
}

.loginColumns img {
  margin: 0 auto;
  width: 170px;
}

.loginColumns {

  display: table-cell;
  vertical-align: middle;
  padding: 0 !important;
}

.loginColumns p {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.8;
  margin-bottom: 20px;
}

.loginColumns .forgot-remember {
  font-size: 11px;
  text-align: center;
  margin-bottom: 15px;
}

.loginColumns .forgot-remember a {
  color: #a5c3cf;
}

.loginColumns .forgot-remember a:hover {
  color: #2574a9;
}

.loginColumns .login-logo {
  margin: 0 auto;
  width: 400px;
  background-color: #fff;
  padding: 30px 20px;
  border-radius: 2px 2px 0 0;
  color: #ffffff;
  text-align: center;
}

.loginColumns .login-form {
  margin: 0 auto;
  width: 400px;
  padding: 30px 20px;
  border-radius: 0 0 2px 2px;
  background-color: rgb(243, 243, 244);
  text-align: center;
  color: rgb(80, 135, 156);
}

.loginColumns .login-form input {
  border-radius: 10px;
  padding: 7px 20px;
  height: auto;
  border: 2px solid #d1e1e7;
}

@media (max-width: 576px) {
  .loginColumns .login-logo,
  .loginColumns .login-form {
    width: 100%;
    padding: 30px 10px;
  }
}

.loginColumns .login-button {
  width: auto;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  border: 2px solid rgb(37, 116, 169);
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  color: rgb(37, 116, 169);
  background: rgba(0, 0, 0, 0) !important;
  transition: all 0.2s ease;
}

.loginColumns .login-button:hover {
  color: #fff !important;
  background: #2574a9 !important;
}

.learner-area .modules-group .module-unit .href .link-file {
  line-height: 15px !important;
}

.tabs-dashboard .notification-counter {
  right: -10% !important;
}

.log-link-message {
  a {
    color: rgb(37, 116, 169);
    text-decoration: underline;
  }
}

.unread-msg {
  .created-at,
  a,
  svg {
    color: rgb(86, 185, 71) !important;
  }
  .initials {
    border: 3px solid rgb(86, 185, 71) !important;
    color: rgb(86, 185, 71) !important;
  }
}
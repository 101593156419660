#messaging-window + #wrapper {
  width: calc(100% - 340px);
}

#messaging-window {
  position: fixed;
  top: 0; right: 0; bottom: 0;
  width: 340px;
  background: #efeef1;
  z-index: 2;

  .messaging-window-container {
    position: absolute;
    top: 0; bottom: 0;
    width: 100%;
    border-left: 1px solid #dad8de;

    .messaging-window-content {
      position: relative;
      height: 100%;

      .chat-header {
        position: relative;
        padding: 15px;
        border-bottom: 1px solid #dad8de;
        background: #efeef1;
        -webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.050);
        -moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.050);
        box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.050);
        z-index: 3;
      }

      .chat-messages {
        position: absolute;
        width: auto;
        height: 100%;
        bottom: 140px;
        right: 0;
        padding: 20px 0 20px;

        .chat-discussion {
          height: 100%;
          background: transparent;
          padding: 0;
        }

        .feed-activity-list {
          .feed-element {
            .well {
              background-color: white;
            }
          }
        }
      }

      .chat-interface {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 140px;
        padding: 20px;
        border-top: 1px solid #dad8de;

        textarea {
          -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
          -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
          box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
          border-radius: 3px;
          resize: none;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  #messaging-window + #wrapper {
    width: 100%!important;
  }
}
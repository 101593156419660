@keyframes placeHolderShimmer{
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
}

.placeholder-container {

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;

  margin: 0;
  margin-bottom:10px;
  width:100%;
  height: 10px;

  display:none;
}

.placeholder-half{
  width:50%!important;
}

.placeholder-quoter{
  width:25%!important;
}
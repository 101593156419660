/* Only demo */
@media (max-width: 1000px) {
  .welcome-message {
    display: none;
  }
}

body {
  background-color: #1585BC;
}

a {
  color: #5BC34B;
}

.panel-title a.btn {
  color: #ffffff !important;
  float: right;
  margin-bottom: 5px !important;
}

.sidebar-collapse {
  .nav > li > a {
    color: #ffffff;
  }
}

.nav-header {
  padding-bottom: 25px;
  background-color: transparent !important;
}

.navbar-header > a {
  color: #ffffff !important;
}

.navbar-top-links .dropdown-user-menu {
  width: 200px;
  min-width: 0;
}

.search-bar {
  border-top: 0;
  padding: 5px 10px 5px 10px;
}

.has-error {
  .help-block {
    color: $red;
  }
}

.wizard > .content {
  background: #ffffff;
  display: block;
  margin: 5px 5px 10px 5px;
  min-height: 120px;
  overflow: hidden;
  position: relative;
  width: auto;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.wizard > .steps a, .wizard > .steps a:hover, .wizard > .steps a:active {
  display: block;
  width: auto;
  margin: 0 0.5em 0.5em;
  padding: 8px;
  text-decoration: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.wizard > .steps .current a, .wizard > .steps .current a:hover, .wizard > .steps .current a:active {
  background: #1585BC;
  color: #fff;
  cursor: default;
}

.wizard > .steps .disabled a, .wizard > .steps .disabled a:hover, .wizard > .steps .disabled a:active {
  background: #eee;
  color: #aaa;
  cursor: default;
}

.wizard > .steps .done a, .wizard > .steps .done a:hover, .wizard > .steps .done a:active {
  background: #eee;
  color: #aaa;
}

.wizard > .actions a, .wizard > .actions a:hover, .wizard > .actions a:active {
  background: #1585BC;
  color: #fff;
  display: block;
  padding: 0.5em 1em;
  text-decoration: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.swal2-container {
  z-index:2100 !important;
}
.qualification-module-create .VueTables__search{
  display:none;
}

[v-cloak] {
  display: none;
}

.vue-form-wizard .wizard-icon-container,
.vue-form-wizard .wizard-progress-bar,
.vue-form-wizard .wizard-icon-circle,
.vue-form-wizard .wizard-header
{
  display:none !important;
}

.vue-form-wizard .wizard-nav-pills {
  margin: 0 5px !important;
}

.vue-form-wizard li {
  width:25% !important;
  padding:8px !important;
  background: #eee !important;
  border-radius:3px !important;
  text-align:left !important;
  color:#aaa !important;
  margin:0 10px 0 0 !important;
}

.vue-form-wizard .wizard-nav-pills>li>a{
  top:0 !important;
  color:#aaa !important;
  flex-direction: initial !important;

}

.vue-form-wizard li.active {
  background: #1585BC !important;
  color:#fff !important;
  text-align:left !important;
}

.vue-form-wizard li.active span.stepTitle {
  color:#fff !important;
  text-align:left !important;
}

a .contact-box {
  color: #e1e1e1!important;
  border: 2px dashed #e1e1e1;
}

.contact-box {
  min-height: 180px!important;

  &.programme-template-card {
    font-size: 16px;
    min-height: 190px!important;
    overflow-x:hidden!important;

    h3 {
      min-height:35px;
    }
  }

  &.programme-card {
    font-size: 16px;
    min-height: 230px!important;
  }

  &.learner-card {
    font-size: 16px;
    min-height: 195px!important;
  }

}

a .module-box {
  color: #666666!important;
  border: 2px dashed #666666;
}

.module-box {
  min-height: 30px!important;
  font-size: 14px;
}

.resource-box {
  background-color: #ffffff;
  border: 1px solid #e7eaec;
  padding: 20px;
  margin-bottom: 20px;
  min-height: 150px!important;
  font-size: 14px;
}

.activities-box {
  background-color: #ffffff;
  border: 1px solid #e7eaec;
  padding: 10px 5px;
  margin-bottom: 20px;
  min-height: 200px!important;
  font-size: 14px;
}

table.table-programme {
  color: black;

  th {
    padding: 1.5em!important;
  }

  td {
    position: relative;
    vertical-align: middle!important;
    padding: 1.5em!important;

    &.cell-week {
      text-align: center;
      font-weight: bold;
      padding: 1.5em 1.0em!important;
    }

    &.cell-activity {
      text-align: center;
    }
  }
}

table.table-programme-shade {
  opacity:0.4!important;
}

div.toolbar-row {
  padding: 0 10px;
}

div.toolbar {
  display: table;
  width: 100%;

  > div {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
  }

  .toolbar__add {
    width: 48px;

    .btn {
      border-top: 0;
      border-bottom: 0;
      border-radius: 0;
      height: 100%;
      padding: 0.425em 1.250em 0;

      i.fa {
        padding-top: 12px;
      }
    }
  }

  .toolbar__filter {
    border-right: 1px solid #e7eaec;
    width: calc(100% - 40px - 86px / 2);

    .input-group {
      width: 100%;

      label {
        cursor: pointer;
        padding: 1.150em;
        margin-bottom: 0;
        float: left;
        width: 40px;
      }

      input {
        padding: 2em 0;
        border: 0;
        border-radius: 0;
        width: calc(100% - 40px);
      }
    }
  }

  .toolbar__tagging {
    width: calc(100% - 40px - 86px / 2);

    .chosen-container {
      height: 100%;

      .chosen-choices {
        height: 100% !important;
        border: 0px;
        padding-top: 10px;
      }

      &.chosen-container-active {
        .chosen-choices {
          box-shadow: none;
        }
        .chosen-drop {
          border: 0;
        }
      }
    }
  }

  .toolbar__sorting {
    width: 86px;

    .btn-group {
      float: right;
      height: 100%;

      .btn {
        border-top: 0;
        border-bottom: 0;
        border-color: #e7eaec;
        border-radius: 0;
        height: 100%;
      }
    }
  }
}

.vue-form-wizard .wizard-card-footer {
  justify-content: space-between !important;
}

.vue-form-wizard .wizard-card-footer .wizard-footer-left {
  flex: 0 !important;
}

[v-cloak] {
  display:none;
}

.dropdown-toggle{
  border: 1px solid #e5e6e7 !important;
  border-radius: 1px !important;
}

.search-panel .dropdown-toggle {
  border: 0 !important;
}

.contact-box.programme-template-card p, .contact-box.programme-card p , .contact-box.learner-card p {
  font-size: 12px;
}

.chat-discussion{
  display: flex;
  flex-flow: column nowrap;
  .chat-message:first-child {
   margin-top: auto;
  }
}

.messaging-window-container .no-messages {
  position: absolute;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #676a6c;
  padding: 0 50px;
  width:100%;
}

.message-content {
  word-break: break-word;
  max-width:100%;
}

.loading-messages {
  background-color: #2095D2;
  text-align: center;
  padding: 20px;
  color: #fff;
  position:absolute;
  z-index: 10;
  width: 100%;
  opacity: 0.8;
  top:0;
}

.user-friends a {
  position:relative;
}

.recipient-delete{
  position: absolute;
  right: 0px;
  top: -20px;
  cursor:pointer;
}

#messaging-window {
  display:none;
  top: 60px !important;
  z-index:10;
}

#messaging-window .messaging-window-container .messaging-window-content .chat-messages {
  padding: 20px 0 10px;
}

.close-message-button{
  text-align:right;
  margin-bottom:15px;
  cursor:pointer;
}

.no-activity-feeds{
  text-align:center;
}

#programme-progress table.table-programme {
  table-layout: fixed;
}

#programme-progress .label-warning {
  margin-right:4px;
}

#log-book {

  .multiselect {
    font-size: 12px !important;
  }

  .logging-select .multiselect__option {
    min-height: 22px;
    padding-top: 10px;
    font-size: 12px;
  }

  .multiselect__option--disabled {
    padding-left:20px!important;
  }

  .multiselect__option {
    padding-left:40px;
  }

  .logging-select .multiselect__option--highlight {
    background: #1585BC !important
  }
  .logging-select .multiselect__tags {
    padding: 0px 40px 0 8px;
  }
  .logging-select .multiselect__input {
    padding: 5px 0 0 5px;
    font-size: 12px !important;
  }
  .logging-select .multiselect {
  }
  .logging-select .multiselect__tag {
    background: #56b947;
    padding: 5px;
    padding-right: 25px;
    height: auto;
    font-size: 12px;
  }
  .multiselect__tags-wrap {
    display: block;
    margin-top: 5px;
  }

  .input-group-btn{
    vertical-align: top !important;
    padding-top: 1px !important;
  }
}

.chat-user.active{
  background:rgb(231, 234, 236);
}

.recipients-tab{
  margin-left:-30px!important;

  div {
    cursor:pointer;
    text-align:center;
    border-right:1px solid #fff;
    color:#fff;
    background:rgb(21, 133, 188) !important;
    padding:10px;
  }
}

.chat-discussion{
  height:440px;
  position:relative;
  padding:0px;

  .no-messages{
    position:absolute;
    text-align:center;
    width:100%;
    top:40%;
    height:100px;
    padding-top:60px;
    background: url('/images/messages.png') no-repeat 50% 0%;
  }
}

.recipient-user{
  display:inline-block;
  padding:2px 5px;
  border-radius:5px;
  color:#fff;
  background:rgb(21, 133, 188);
  margin-right:5px;
}

.group-user-list{
  svg{
    display:inline-block;
    margin-right:10px;
  }
  div{
    display:inline-block;
    vertical-align: top;
    line-height: 40px;
  }
  line-height:20px;
  padding:15px;
}

.chat-user{
  cursor:pointer;
  img {
    border-radius:20px;
  }
}

.chat-user-name.new {
  font-weight:bold !important;
}

.log-link {
  cursor:pointer;
}

.tutor-profile-avatar img {
  max-width:100% !important;
}

.learner-profile-img {
  max-width:92px;
}

.resource-module .programme-template-card h3 {
  font-size:14px;
}

.resource-box h3 {
  font-size:14px;
}

.notification-options{
  label {
    font-weight:normal;
  }
}

.notification-counter-message {
  position: absolute;
  top: 10px;
  right: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  color: #FFF;
  border-radius: 100%;
  background: #56b947;
}


.contact-details a{
  color:#666;
  text-decoration:underline;
  :hover{
    text-decoration:none;
  }
}

.mini-navbar img.side-menu-brand {
  display: block !important;
  width:48px;
  margin: 0 auto;
}

.mini-navbar span.notification-counter-message {
  display:flex !important;
  width:15px;
  height:15px;
  font-size:10px;
  right:25%;
}

.table .label,
.tab-content .label {
    display:inline-block !important;
    margin-right:2px;
}

.learner-notification{
  font-weight:bold !important;
}

.resource-individual-learner-box{
   min-height:100px!important;
   padding:10px 5px;

  h3 {
    font-size:14px;
  }
}

.toolbar__add .btn i.fa {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.toolbar {
  .dropdown-menu {
    margin-left:-70px;
  }
  .dropdown-toggle .fa{
    padding-left:10px;
  }
}


.progress-learner-fine {
  background: url('/images/todo.png') no-repeat 50% 50%;
  text-align: center;
  width: 49px;
  height:60px;
  margin: 10px auto;
}

.btn-bottom{
  position:absolute;
  bottom:0px;
}

.chat-users{
  height:530px;
}

.archive-message-link {
  color:rgb(21, 133, 188) !important;
  text-decoration:underline;
  margin-top: 8px;
  display: inline-block;
}

.chat-archived{
  .chat-discussion{
    height:530px !important;
  }
}

.chat-message-form {
  position:relative;
  .btn-msg-send {
    position:absolute;
    bottom:10px;
    right:10px;
  }
}

.notifications-list {
  a {
    color:rgb(21, 133, 188) !important;
  }
}

.has-error{
  .chosen-container{
    border:1px solid red;
  }
}

.learner-module{
  ul.nav li.active {
    background: #fff;
  }

  .col-lg-9 {
    .ibox-title {
      border-top: 0;
    }
  }
}

.tutor-dashboard-module{
  .ibox-heading h3 {
    font-size: 18px;
  }
}

.resource-module {
  .programme-template-card {
    h3 {
      height:45px;
      display: block; /* or inline-block */
      overflow:hidden;

      display: -webkit-box;  // needed for the effect to cut off
      -webkit-line-clamp: 3;  // line to cut off
      -webkit-box-orient: vertical;
    }
    small{
      height:60px;
      display: block; /* or inline-block */
      overflow:hidden;

      display: -webkit-box;  // needed for the effect to cut off
      -webkit-line-clamp: 3;  // line to cut off
      -webkit-box-orient: vertical;
    }
  }
}

.project-list table tr td{
  padding:15px 7px!important;
}

.chat-readonly{
  .chat-users{
    height:440px;
  }
}

a.btn-reply{
  color:#fff;
}

.dashboard-client-logo{
  margin-left:20px;
  border:3px solid #fff;
  width:120px;
  height:120px;
  background-position: center center;
  background-size: 85%;
  background-repeat: no-repeat;
  background-color: #FFFFFF;
}

.w-65{
  width:65px;
}

.float-left{
  float:left;
}

.text-center{
  text-align:center;
}

.trainer-file{
  a {
    color: #676a6c;
  }
  margin-right:10px;
}

.pointer{
  cursor:pointer!important;
}

.d-none{
  display:none;
}

.sign-status-tick{
  font-size:20px;
  font-weight:bold;
  color:green;
  display:inline-block;
  margin:0 4px;
}

.unit-name-list{
  padding:10px 0;
}

.lo-status{
  margin-right:10px!important;
}

.label-white{
  color:#676a6c!important;
  background:#fff!important;
}

.grey{
  background-color: #ddd !important;
}

.td-log{
  word-wrap:break-word;
  overflow-x:hidden;
}

.td-log-button {
  display:inline-block;
  border: 1px solid #e7eaec;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 3px;
  touch-action: manipulation;
  cursor: pointer;
  margin-bottom:5px;
}

.rotate div {
  white-space: nowrap;
  transform-origin: left top 0;
  padding:10px;
  position: relative;
}

.w-30{
  width:30px;
}

.positive{
  color: green!important;
}

.negative{
  color: red!important;
}

.horizontal-scroll::-webkit-scrollbar-track
{
  background-color: #FFF !important;
}

.horizontal-scroll::-webkit-scrollbar
{
  height: 8px;
  width: 12px !important;
  background-color: #F5F5F5 !important;
}

.horizontal-scroll::-webkit-scrollbar-thumb
{
  border-radius: 10px !important;
  background-color: #676a6c !important;
  opacity:0.5 !important;
}

.horizontal-scroll::-webkit-scrollbar-thumb:hover
{
  opacity:1 !important;
}